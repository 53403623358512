import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useLenis } from '@studio-freight/react-lenis'
import { styles, theme } from 'app/theme'
import { Media } from 'app/theme/media'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, ReactNode, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
  location: any
  pageContext: any
}

export const Transition = memo(function Transition({
  children,
  location,
  pageContext,
}: Props) {
  const lenisInstance = useLenis()
  const [_loaded, setLoaded] = useState(false)

  useEffect(() => {
    history.scrollRestoration = 'manual'
  }, [])

  const variants = {
    visible: {
      opacity: 1,
      zIndex: pageContext.type === 'home' ? 10000 : 10002,
      transition: {
        duration: 0.4,
        ease: [0.22, 1, 0.36, 1],
      },
    },
    hidden: {
      opacity: 0,
      zIndex: -1,
      transition: {
        delay: pageContext.type === 'home' ? 3 : 1.4,
        duration: 0.6,
        ease: [0.22, 1, 0.36, 1],
      },
    },
  }

  return (
    <AnimatePresence mode="wait">
      <Main key={location.pathname}>
        <Media lessThan="desktopSmall">
          {(className, renderChildren) => {
            return (
              <Aside className={className}>
                {renderChildren ? (
                  <>
                    <SlideIn
                      initial={{ scaleY: 0 }}
                      animate={{ scaleY: 0 }}
                      exit={{ scaleY: 1 }}
                      transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
                    />
                    <SlideOut
                      initial={{ scaleY: 1 }}
                      animate={{ scaleY: 0 }}
                      exit={{ scaleY: 0 }}
                      transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
                    />
                  </>
                ) : null}
              </Aside>
            )
          }}
        </Media>

        <Media greaterThanOrEqual="desktopSmall">
          {(className, renderChildren) => {
            return (
              <Aside className={className}>
                {renderChildren ? (
                  <>
                    <Custom
                      data-type={
                        pageContext.type === 'home' ? 'home' : 'default'
                      }
                      initial="visible"
                      animate="hidden"
                      onAnimationStart={() => {
                        lenisInstance?.stop()

                        setLoaded(true)

                        setTimeout(() => {
                          document
                            .getElementById('hero')
                            ?.classList.add('loaded')
                        }, 100)
                      }}
                      onAnimationComplete={() => {
                        lenisInstance?.start()

                        setLoaded(false)
                      }}
                      variants={variants}
                    >
                      {pageContext.type === 'home' ? (
                        <Wraps>
                          <Wrap>Hotel Ilaria Lucca</Wrap>
                          <Wrap>Hotel Ilaria Lucca</Wrap>
                        </Wraps>
                      ) : null}
                    </Custom>

                    <SlideIn
                      initial={{ scaleY: 1 }}
                      animate={{ scaleY: 0 }}
                      exit={{ scaleY: 0 }}
                      transition={{
                        duration: 0.7,
                        ease: [0.645, 0.045, 0.355, 1.0],
                      }}
                    />
                    <SlideOut
                      initial={{ scaleY: 0 }}
                      animate={{ scaleY: 0 }}
                      exit={{ scaleY: 1 }}
                      transition={{
                        duration: 0.8,
                        ease: [0.645, 0.045, 0.355, 1.0],
                      }}
                    />
                  </>
                ) : null}
              </Aside>
            )
          }}
        </Media>

        {children}
      </Main>
    </AnimatePresence>
  )
})

const Main = styled(motion.main)``

const Aside = styled.aside``

const Custom = styled(motion.div)`
  width: 100%;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  &[data-type='home'] {
    background: ${({ theme }) => theme.colors.variants.primaryLight};
  }
`

const Wraps = styled.div``

const Wrap = styled.div`
  ${styles.label.custom}

  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  opacity: 0.5;
  position: absolute;
  top: 50%;
  left: 2.25rem;
  text-align: center;
  transform: translateY(-50%) scaleX(-1) scaleY(-1);
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  z-index: 2;
  &:last-of-type {
    transform: translateY(-50%) scaleX(1) scaleY(1);
    right: 2.25rem;
    left: auto;
  }
`

const Style = css`
  width: 100%;
  height: 100vh;
  background: ${theme.colors.variants.primaryLight};
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 10002;
`

const SlideIn = styled(motion.div)`
  ${Style}

  transform-origin: bottom;
`

const SlideOut = styled(motion.div)`
  ${Style}

  transform-origin: top;
`
